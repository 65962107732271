import React, { useEffect, useState } from 'react';
import { postContactFormAsync } from '../../Shared/Utilities/ContactFormApi';
import ContactFormInputs from './ContactFormInputs';
import Thankyou from './Thankyou';

const validEmailRegex =
    RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validNameRegex =
    RegExp(/^(?=.{1,40}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/);
const validPhoneRegex =
    RegExp(/\d{1,15}$/);
const validCompanyRegex =
    RegExp(/^(?=.{1,40}$)[a-zA-Z0-9]+(?:[-'\s][a-zA-Z]+)*$/)

const ContactForm = () => {
    const [formInputs, setFormInputs] = useState({
        name: null,
        company: null,
        email: null,
        phoneNumber: null,
        enquiry: null,
        agreedTerms: false,
    });

    const [errors, setErrors] = useState({
        name: '',
        company: '',
        email: '',
        phoneNumber: '',
        enquiry: '',
        agreedTerms: '',
    })

    const [success, setSuccess] = useState(false);
    const [warning, setWarning] = useState();
    const [additionalInfo, setAdditionalInfo] = useState({});

    const handleCheck = (evt) => {
        let name = evt.target.name;
        let checked = evt.target.checked

        setFormInputs({ ...formInputs, [name]: checked });
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);

        const paramsObj = Array.from(params.keys()).reduce(
            (acc, val) => ({ ...acc, [val]: params.get(val) }),
            {}
        );
        setAdditionalInfo(paramsObj);

    }, []);

    const handleChange = (evt) => {
        evt.preventDefault();
        const { name, value } = evt.target;

        setFormInputs({ ...formInputs, [name]: value });
    }

    const handleValidation = () => {
        let fields = formInputs;
        let errors = {};
        let formIsValid = true;

        //Name
        if (!fields["name"]) {
            formIsValid = false;
            errors["name"] = "Name is required";
        } else {
            if (!validNameRegex.test(fields["name"])) {
                formIsValid = false;
                errors["name"] = "Please enter a valid name";
            }
        }

        //Company
        if (!fields["company"]) {
            formIsValid = false;
            errors["company"] = "Company name is required";
        } else {
            if (!validCompanyRegex.test(fields["company"])) {
                formIsValid = false;
                errors["company"] = "Please enter a valid company name";
            }
        }

        //Email
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Email is required";
        } else {
            if (!validEmailRegex.test(fields["email"])) {
                formIsValid = false;
                errors["email"] = "Please enter a valid email address";
            }
        }

        //PhoneNumber
        if (!fields["phoneNumber"]) {
            formIsValid = false;
            errors["phoneNumber"] = "Phone number is required";
        } else {
            if (!validPhoneRegex.test(fields["phoneNumber"])) {
                formIsValid = false;
                errors["phoneNumber"] = "Please enter a valid phone number";
            }
        }

        //Enquiry
        if (!fields["enquiry"]) {
            formIsValid = false;
            errors["enquiry"] = "Please enter a message";
        }

        //privacy
        if (!fields["agreedTerms"]) {
            formIsValid = false;
            errors["agreedTerms"] = "Privacy policy must be accepted";
        }

        setErrors(errors);
        return formIsValid;
    }

    const handleSubmit = async () => {
        setWarning(null);

        if (handleValidation()) {
            let data = {
                name: formInputs.name,
                company: formInputs.company,
                email: formInputs.email,
                phoneNumber: formInputs.phoneNumber,
                enquiry: formInputs.enquiry,
                agreedTerms: formInputs.agreedTerms,
                additionalInfo: additionalInfo
            }

            let response = await postContactFormAsync(data);
            setSuccess(response);

        } else {
            setWarning("Please fix the below errors");
        }
    }

    return (
        <>
            {success ? <Thankyou /> :
                <>
                    <div className="contact-form">
                        <ContactFormInputs state={formInputs} errors={errors} warning={warning} handleChange={handleChange} handleCheck={handleCheck} handleSubmit={handleSubmit} />
                    </div>
                </>
            }
        </>
    );
};

export default ContactForm;